import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import Image from 'next/image';
import { WEB_APP } from 'src/static/app';

const Container: NextPage = (props) => {
  return (
    <>
      <NextSeo title={`管理画面 | ${WEB_APP.name.default} `} />
      <div className='max-w-screen-lg h-[80vh] mx-auto flex flex-col items-center justify-center gap-4 py-16 px-4'>
        <div>
          <Image
            src='/img/crash-blue.webp'
            width={400}
            height={400}
            alt='ページが見つかりませんでした'
          />
        </div>
        <h1 className='text-2xl font-bold'>ページが見つかりませんでした</h1>
        <div>
          <p>お探しのページはすでに削除されているか、参照先が変更された可能性があります。</p>
        </div>
      </div>
    </>
  );
};

export default Container;
